import React, { useRef, useEffect } from "react";
import "../scss/AudioPlayer.scss";
import { MUSIC_LIST } from "../constants/constants";
import {
  calculateTotalValue,
  calculateCurrentValue,
} from "./audioPlayer.utils";
import Bar from "./Bar";
import { AudioContext } from "../context/context";
import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faBackward,
  faForward,
} from "@fortawesome/free-solid-svg-icons";

function AudioPlayer() {
  const audioPlayerRef = useRef(null);
  const {
    curTime,
    setDuration,
    setCurTime,
    clickedTime,
    duration,
    isPlaying,
    setIsPlaying,
    setClickedTime,
    currentMusicIndex,
    setCurrentMusicIndex,
    setCurrentSong,
    canPlayThrough,
    setCanPlayThrough,
  } = useContext(AudioContext);

  const currentSong = MUSIC_LIST[currentMusicIndex];

  // using action changes song
  const handleChangeSong = (action) => {
    if (action === "forward" && currentMusicIndex < MUSIC_LIST.length - 1) {
      setCurrentMusicIndex(currentMusicIndex + 1);
    } else if (action === "backward" && currentMusicIndex !== 0) {
      setCurrentMusicIndex(currentMusicIndex - 1);
    } else {
      setCurrentMusicIndex(action === "forward" ? 0 : MUSIC_LIST.length - 1);
    }
  };

  // Play/ Pause button
  const handlePlayAudio = () => {
    const audio = document.getElementById("myAudio");
    isPlaying ? audio.pause() : audio.play();
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    audioPlayerRef.current.load();
    isPlaying && audioPlayerRef.current.play();
    setCurrentSong(currentSong);
  }, [currentSong]);

  useEffect(() => {
    const audio = document.getElementById("myAudio");
    // state setters wrappers
    const setAudioData = () => {
      setDuration(audio.duration);
      setCurTime(audio.currentTime);
    };

    const setAudioTime = () => setCurTime(audio.currentTime);

    // DOM listeners: update React state on DOM events
    audio.addEventListener("loadeddata", setAudioData);

    audio.addEventListener("timeupdate", setAudioTime);

    audio.addEventListener("waiting", setCanPlayThrough(false));

    audio.addEventListener("canplaythrough", setCanPlayThrough(true));

    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    }

    // effect cleanup
    return () => {
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("timeupdate", setAudioTime);
    };
  }, [isPlaying, clickedTime, currentMusicIndex]);

  const handleClickTime = (time) => {
    setClickedTime(time);
  };

  return (
    <>
      <div id="app-cover">
        <audio
          preload="audio"
          onEnded={() => handleChangeSong("forward")}
          id="myAudio"
          ref={audioPlayerRef}
        >
          <source src={currentSong.src} type="audio/mp3" />
        </audio>
        <div id="bg-artwork"></div>
        <div id="bg-layer"></div>
        <div id="player">
          <div id="player-track" className={isPlaying ? "active" : "inactive"}>
            <div id="album-name">DZG Playlist</div>
            <div id="track-name">{currentSong.title}</div>
            <div id="track-time" className={isPlaying ? "active" : "inactive"}>
              <div id="current-time">{calculateCurrentValue(curTime)}</div>
              <div id="track-length">{calculateTotalValue(duration)}</div>
            </div>
            <div id="s-area">
              <div id="ins-time"></div>
              <div id="s-hover"></div>
              <div id="seek-bar"></div>
              <Bar
                curTime={curTime}
                duration={duration}
                onTimeUpdate={(time) => handleClickTime(time)}
              />
            </div>
          </div>
          <div id="player-content">
            <div id="album-art" className={isPlaying ? "active" : "inactive"}>
              {canPlayThrough ? (
                <img src={currentSong.albumArt} className="active" id="_1" />
              ) : (
                <div id="buffer-box">Buffering ...</div>
              )}
            </div>
            <div id="player-controls">
              <div className="control">
                <div className="button" id="play-previous">
                  <FontAwesomeIcon
                    onClick={() => handleChangeSong("backward")}
                    icon={faBackward}
                  />
                </div>
              </div>
              <div className="control">
                <div
                  className="button"
                  onClick={handlePlayAudio}
                  id="play-pause-button"
                >
                  {/* <i className={`fas fa-${!isPlaying ? "play" : "pause"}`}></i> */}
                  <FontAwesomeIcon icon={!isPlaying ? faPlay : faPause} />
                </div>
              </div>
              <div className="control">
                <div className="button" id="play-next">
                  <FontAwesomeIcon
                    onClick={() => handleChangeSong("forward")}
                    icon={faForward}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AudioPlayer;
