import React, { useContext, createContext, useState } from "react";

export const AudioContext = createContext();

export const useAudioContext = () => {
  const context = useContext(AudioContext);

  if (!context)
    throw new Error(
      "useAudioContext can only be used with useAudioContextProvider"
    );

  return context;
};

export const AudioContextProvider = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState();
  const [curTime, setCurTime] = useState();
  const [clickedTime, setClickedTime] = useState();
  const [currentMusicIndex, setCurrentMusicIndex] = useState(0);
  const [currentSong, setCurrentSong] = useState(0);
  const [canPlayThrough, setCanPlayThrough] = useState(false);

  const { children } = props;
  const value = {
    isPlaying,
    setIsPlaying,
    duration,
    setDuration,
    curTime,
    setCurTime,
    clickedTime,
    setClickedTime,
    currentMusicIndex,
    setCurrentMusicIndex,
    currentSong,
    setCurrentSong,
    canPlayThrough,
    setCanPlayThrough,
  };

  return (
    <AudioContext.Provider value={value}>{children}</AudioContext.Provider>
  );
};
