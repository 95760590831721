import relaxing from "../audio/bensound-relaxing.mp3";
import nature from "../audio/Free-nature-sounds.mp3";
import rasta from "../audio/alexander-nakarada-sunny-rasta.mp3";
import day from "../audio/bensound-adaytoremember.mp3";
import better from "../audio/bensound-betterdays.mp3";
import cute from "../audio/bensound-cute.mp3";
import nov from "../audio/bensound-november.mp3";
import piano from "../audio/bensound-pianomoment.mp3";
import slow from "../audio/bensound-slowmotion.mp3";
import tomorrow from "../audio/bensound-tomorrow.mp3";
import singingBowls from "../audio/relaxtion-meditation-music.mp3";
import space from "../audio/space.wav";
import {
  faMortarPestle,
  faUserAstronaut,
  faTree,
} from "@fortawesome/free-solid-svg-icons";

export const MUSIC_LIST = [
  {
    title: "Piano Pup",
    src: piano,
    albumArt:
      "https://images.unsplash.com/photo-1554692844-6627ca340264?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=80",
  },
  {
    title: "Calming Tune",
    src: tomorrow,
    albumArt:
      "https://images.unsplash.com/photo-1477884213360-7e9d7dcc1e48?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80",
  },
  {
    title: "Belly Rub",
    src: slow,
    albumArt:
      "https://images.unsplash.com/photo-1548199973-03cce0bbc87b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80",
  },
  {
    title: "Walk in the Park",
    src: nov,
    albumArt:
      "https://images.unsplash.com/photo-1504208434309-cb69f4fe52b0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80",
  },
  {
    title: "Relaxing Melody",
    src: relaxing,
    albumArt:
      "https://images.unsplash.com/photo-1505628346881-b72b27e84530?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80",
  },
  {
    title: "Rasta",
    src: rasta,
    albumArt:
      "https://images.unsplash.com/photo-1548439739-0cf616cef1cd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1275&q=80",
  },
  {
    title: "Sunny Days",
    src: day,
    albumArt:
      "https://images.unsplash.com/photo-1521218597721-2e247daed5db?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2253&q=80",
  },
  {
    title: "Better Days",
    src: cute,
    albumArt:
      "https://images.unsplash.com/photo-1562176566-e9afd27531d4?ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80",
  },
  {
    title: "Puppy Eyes",
    src: better,
    albumArt:
      "https://images.unsplash.com/photo-1569972120585-20ec0d931c7f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2225&q=80",
  },
];

export const AMBIENT_AUDIO_PLAYERS = [
  {
    title: "Nature",
    src: nature,
    icon: faTree,
    description: "Birds chirping, peaceful running water.",
  },
  {
    title: "Singing Bowls",
    src: singingBowls,
    icon: faMortarPestle,
    description: "Tibetan singing bowls and bells, mediation sounds",
  },
  {
    title: "Space Ambience",
    src: space,
    icon: faUserAstronaut,
    description: "Deep space ambience, calming synthesizer noises",
  },
  // {
  //   title: "Coming soon...",
  //   src: nature,
  //   icon: "peace",
  //   description: "Coming soon...",
  // },
];
