import React, { useContext } from "react";
import { MUSIC_LIST } from "../constants/constants";
import { AudioContext } from "../context/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMusic } from "@fortawesome/free-solid-svg-icons";

function MusicList() {
  const { currentSong, setCurrentMusicIndex } = useContext(AudioContext);

  return (
    <div>
      {MUSIC_LIST.map(({ title, src, icon }, i) => {
        return (
          <div
            className={`song-item ${
              currentSong.title === title ? "active" : ""
            }`}
            data-src={src}
            onClick={() => setCurrentMusicIndex(i)}
            key={title}
          >
            <FontAwesomeIcon icon={faMusic} />
            {title}
          </div>
        );
      })}
    </div>
  );
}

export default MusicList;
