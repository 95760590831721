import React from "react";
// import { Helmet } from "react-helmet";
// import styles from "./blog.module.css";
import Layout from "../components/layout";
import "../scss/App.scss";
import "../scss/HeaderContainer.scss";
import AudioPlayer from "../components/AudioPlayer";
// import Navigation from "../components/Navigation";
import MusicList from "../components/MusicList";
import SocialBar from "../components/SocialBar";
import AmbientAudioPlayer from "../components/AmbientAudioPlayer";
import { AMBIENT_AUDIO_PLAYERS } from "../constants/constants";
import DogGraphic from "../components/DogGraphic";
import { AudioContextProvider } from "../context/context";

class AudioGenerator extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <AudioContextProvider>
          <div className="App">
            <SocialBar />
            <div className="header_container">{/* <Navigation /> */}</div>
            <div className="audio-options_container">
              <div className="two-col">
                <AudioPlayer />
                <div className="dog-graphic">
                  <DogGraphic />
                </div>
              </div>

              <div className="audio-options_width">
                <div className="ap_col">
                  <h2>Music Playlist</h2>
                  <h3 className="note">
                    Choose a peaceful song to start or just press play.
                  </h3>
                  <div className="song-list">
                    <MusicList />
                  </div>
                </div>
                <div className="ap_col">
                  <h2>Calming Ambient Sounds</h2>
                  <h3 className="note">
                    Experiment with mixing in these sounds.
                  </h3>
                  <div className="sound_col">
                    {AMBIENT_AUDIO_PLAYERS.map(
                      ({ title, src, icon, description }) => {
                        return (
                          <AmbientAudioPlayer
                            key={title}
                            title={title}
                            src={src}
                            icon={icon}
                            description={description}
                          />
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
            <p className="pharsyde">
              Made with <span>&#10084;</span> by Pharsyde Studio
            </p>
          </div>
        </AudioContextProvider>
      </Layout>
    );
  }
}

export default AudioGenerator;
