import React from "react";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

function SocialBar() {
  return (
    <div className="social">
      <div>Share</div>
      <FacebookShareButton url={"www.dogzengarden.com"}>
        <FacebookIcon size={50} round={true} />
      </FacebookShareButton>
      <EmailShareButton url={"www.dogzengarden.com"}>
        <EmailIcon size={50} round={true} />
      </EmailShareButton>
      <RedditShareButton url={"www.dogzengarden.com"}>
        <RedditIcon size={50} round={true} />
      </RedditShareButton>
      <WhatsappShareButton url={"www.dogzengarden.com"}>
        <WhatsappIcon size={50} round={true} />
      </WhatsappShareButton>
      <TwitterShareButton url={"www.dogzengarden.com"}>
        <TwitterIcon size={50} round={true} />
      </TwitterShareButton>
    </div>
  );
}

export default SocialBar;
