import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AmbientAudioPlayer({ src, icon, title, description }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volumeRangeValue, setVolumeRangeValue] = useState(0.3);
  const [canPlayThrough, setCanPlayThrough] = useState(false);

  const handlePlayPause = () => {
    const player = document.getElementById(`ambient-play-${title}`);
    setIsPlaying(!isPlaying);
    !isPlaying ? player.play() : player.pause();
  };

  const handleVolume = (e) => {
    const player = document.getElementById(`ambient-play-${title}`);
    e.stopPropagation();
    const volumeLevel = e.target.value / 10;
    player.volume = volumeLevel;
    setVolumeRangeValue(volumeLevel);
  };

  useEffect(() => {
    const player = document.getElementById(`ambient-play-${title}`);
    player.volume = volumeRangeValue;
    player.addEventListener("canplaythrough", () => {
      setCanPlayThrough(true);
    });
  }, []);

  return (
    <div
      onClick={() => handlePlayPause()}
      className={`ambient-audio-player song-item ${
        isPlaying && canPlayThrough ? "active" : "inactive"
      }`}
      data-src={src}
    >
      <audio id={`ambient-play-${title}`} preload="audio">
        <source src={src} type="audio/mp3" />
      </audio>
      <div>
        <FontAwesomeIcon icon={icon} />
        {title}
      </div>
      <p className="description">{description}</p>
      {isPlaying && canPlayThrough && (
        <input
          type="range"
          id="points"
          name="points"
          min="0"
          max="10"
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => handleVolume(e)}
          value={volumeRangeValue * 10}
        />
      )}
      {isPlaying && !canPlayThrough && <p>Loading....</p>}
    </div>
  );
}

export default AmbientAudioPlayer;
